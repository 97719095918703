export const ListMarker = () => {
  return (
    <svg
      width="20"
      height="27"
      viewBox="0 0 20 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="shrink-0 grow-0"
    >
      <path
        d="M3.08976 3.54702L11.3098 3.5635L16.8094 13.0442L8.58932 13.0278L3.08976 3.54702Z"
        fill="#C93D94"
      />
      <path
        d="M3.12697 22.4858L11.347 22.5023L16.8078 13.044L8.58775 13.0275L3.12697 22.4858Z"
        fill="url(#paint0_linear_5866_2869)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5866_2869"
          x1="16.8078"
          y1="12.9147"
          x2="16.8078"
          y2="22.5282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DB7EB8" />
          <stop
            offset="1"
            stopColor="#C93D94"
          />
        </linearGradient>
      </defs>
    </svg>
  );
};
