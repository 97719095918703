import { BeforeAfterSlider } from '@/components/Common/BeforeAfterSlder';
import { ListMarker } from '@/images/svg/ListMarker';

export interface IServiceCardProps {
  id: number;
  image?: string;
  bgColor: string;
  slider?: {
    before: string;
    after: string;
  };
  preTitle: string;
  title: string;
  description: string;
  items: string[];
  index: number;
  isTablet: boolean;
  buttonText: string;
}

export interface IProps extends IServiceCardProps {
  onButtonClick: () => void;
}

export const ServiceCard = ({
  index,
  items,
  image,
  slider,
  bgColor,
  description,
  title,
  preTitle,
  isTablet,
  buttonText,
  onButtonClick,
}: IProps) => {
  return (
    <div
      className="
       flex justify-center items-center pt-[40px]
       lg:sticky lg:top-0 lg:pt-0
       lg-2:px-[60px] lg:h-[100vh] lg:max-h-[909px]
       lg:mt-[-150px] lg:mb-[-150px]
       [@media_((min-width:1024px)_and_(max-height:850px))]:pt-[120px]
       [@media(max-height:850px)]:mb-0
       pointer-events-none
    "
    >
      <div
        className="
          flex flex-col rounded-[32px] w-full
          lg:flex-row
          lg:relative lg:w-[1108px] lg:h-[512px]
          pointer-events-auto
        "
        style={{ top: `calc(-5% + ${index * 58}px)` }}
      >
        {image && (
          <div
            className="
              w-[44.3%] hidden rounded-l-[16px] bg-center bg-no-repeat
              md-tablet:h-auto md-tablet:block
            "
            style={{
              backgroundImage: `url('${image}')`,
            }}
          />
        )}
        {slider && (
          <div
            className="w-[44.3%] rounded-l-[16px] flex items-center justify-center"
            style={{ backgroundColor: bgColor }}
          >
            {!isTablet && (
              <BeforeAfterSlider
                beforeImage={slider.before}
                afterImage={slider.after}
              />
            )}
          </div>
        )}

        <div
          className="
           p-[20px] flex flex-col justify-between gap-[10px]
           lg:w-[55.7%] lg:rounded-r-[16px]
           lg:p-[40px]
           xl-1:pl-[115px] xl-1:pt-[25px] xl-1:pb-[40px] xl-1:pr-[16px]
          "
          style={{ backgroundColor: bgColor }}
        >
          <div>
            {preTitle && <p className="text-purple-1 mb-[7px] font-mono">{preTitle}</p>}
            {title && <h4 className="text-purple-4 mb-[12px] text-[20px] lg:text-[30px] leading-[36px]">{title}</h4>}
            {description && (
              <p className="text-purple-1 mb-[16px] lg:mb-[36px] lg:text-[20px] leading-[28px]">{description}</p>
            )}
            {items && (
              <ul>
                {items.map((item, idx) => {
                  return (
                    <li
                      key={idx}
                      className="flex items-center leading-[24px] text-purple-1 mb-[8px] lg:mb-[18px] last:mb-0"
                    >
                      <ListMarker />
                      <span className="ml-[10px]">{item}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>

          <button
            onClick={onButtonClick}
            className="
              underline underline-offset-2 hover:opacity-80 text-white self-center
              transition duration-300 ease-out hover:scale-[1.05] cursor-pointer
            "
            aria-label="Open Contact Us popup"
            title="Open Contact Us popup"
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};
