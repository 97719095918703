export const MediumSizeLens = () => {
  return (
    <svg
      width="1611"
      height="990"
      viewBox="0 0 1011 990"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute top-[-50%] right-0"
    >
      <g
        style={{ mixBlendMode: 'hard-light' }}
        opacity="0.1"
        filter="url(#filter0_f_5866_2624)"
      >
        <ellipse
          cx="669.651"
          cy="494.593"
          rx="446.292"
          ry="189.005"
          transform="rotate(-22.5314 669.651 494.593)"
          fill="#2E42FF"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_5866_2624"
          x="0.878433"
          y="0.0457916"
          width="1337.55"
          height="989.094"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="125.081"
            result="effect1_foregroundBlur_5866_2624"
          />
        </filter>
      </defs>
    </svg>
  );
};
