import { ArrowWithPlume } from '@/components/Shapes';
import { ContactFrom } from '@/components/Forms/Contact';
import './index.scss';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

interface ContactFormPopupProps {
  show: boolean;
  onClose: () => void;
}

const ContactFormPopup = ({ show, onClose }: ContactFormPopupProps) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [show]);

  return (
    <div
      className={`fixed inset-0 z-[1000] bg-black bg-opacity-70 transition-opacity overflow-y-scroll
          ${show ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
      onClick={onClose}
    >
      <div
        className={clsx(`py-[60px] px-[18px] w-full flex items-start justify-center`, {
          ['h-[100vh] items-center']: isFormSubmitted,
        })}
      >
        <div
          className={clsx(
            `overflow-hidden sm:max-w-[680px] w-full rounded-[16px]
            py-[30px] pr-[30px] pl-[35px] sm:py-[50px] sm:pr-[65px] sm:pl-[70px] m-[10px] sm:m-[20px] 
            relative bg-black-10 transform transition-transform ${show ? 'translate-y-0' : 'translate-y-full'}`,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={clsx(``, {
              ['absolute z-[-1] w-full left-0 top-0 overflow-hidden !h-full']: isFormSubmitted,
            })}
          >
            <div
              className="
                h-[150px] sm:h-[267px] w-[37px] sm:w-[67px] top-[50px] left-0 z-[-1]
                position absolute bg-no-repeat bg-center bg-contain pointer-events-none
              "
              style={{ backgroundImage: `url('/images/contact-us/crop-arrow.svg')` }}
            ></div>
            <ArrowWithPlume
              arrowName="arrow-with-plume-pink"
              classname="h-[250px] sm:h-[439px] w-[70px] sm:w-[110px]
               bottom-[-95px] right-[100px] sm:bottom-[-200px] md-tablet:bottom-[-185px] sm:right-[130px]"
            />
          </div>

          <h5
            className={`
              text-[28px] sm:text-4xl text-white mb-[10px] sm:mb-[15px] text-center
          `}
          >
            {/* eslint-disable-next-line max-len */}
            Succeed <span className="font-monoItalic font-light text-purple-7 italic tracking-[-1.2px]">
              faster
            </span>{' '}
            with Syberry.
          </h5>
          <p className="text-[14px] sm:text-[22px] text-white mb-[15px] sm:mb-[25px] text-center">
            Get in touch to discuss your vision—for your software and your business.
          </p>
          <button
            className="absolute top-[15px] right-[10px] sm:right-[30px] text-grey-6 text-3xl sm:text-4xl"
            onClick={onClose}
          >
            &#10005;
          </button>
          <ContactFrom
            testId="contact-us-popup"
            showPotentialRole={false}
            submitButtonClassName="mt-[10px] sm:mt-[30px]"
            setFormSubmissionStatus={setIsFormSubmitted}
            popupForm
          />
        </div>
      </div>
    </div>
  );
};
export default ContactFormPopup;
