'use client';

import React, { useState } from 'react';
import Image from 'next/image';
import './index.scss';
import clsx from 'clsx';
import dynamic from 'next/dynamic';

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

interface IVideoBackgroundIntroProps {
  video: string;
  image: string;
}

export const VideoBackgroundIntro = ({ video, image }: IVideoBackgroundIntroProps) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  return (
    <div className="absolute min-h-[700px] h-full w-full z-[1] top-[0]">
      <div
        className={clsx('block', {
          ['hidden w-0']: !isVideoLoaded,
        })}
      >
        <ReactPlayer
          url={video}
          controls={false}
          loop
          playing
          muted
          playsinline
          fallback={
            <Image
              src={image}
              alt="Background"
              loading="lazy"
              width={0}
              height={0}
            />
          }
          onReady={() => {
            if (isVideoLoaded) {
              return;
            }

            setIsVideoLoaded(true);
          }}
          className="absolute !w-full -z-[5] top-0 left-0 react-player intro max-h-[800px] pointer-events-none"
        />
      </div>

      <Image
        src={image}
        alt="Intro"
        width={0}
        height={0}
        className={clsx('block absolute h-full w-full -z-[10] top-0 left-0 object-cover video-image', {
          ['hidden']: isVideoLoaded,
        })}
      />
    </div>
  );
};

export default VideoBackgroundIntro;
