export const Lens = () => {
  return (
    <>
      <svg
        width="554"
        height="988"
        viewBox="0 0 554 988"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute left-0 top-0"
      >
        <g
          style={{ mixBlendMode: 'hard-light' }}
          opacity="0.3"
          filter="url(#filter0_f_5866_2591)"
        >
          <ellipse
            cx="-85.4348"
            cy="494.199"
            rx="373.466"
            ry="158.163"
            transform="rotate(-22.5314 -85.4348 494.199)"
            fill="#2E42FF"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_5866_2591"
            x="-724.656"
            y="0.772888"
            width="1278.44"
            height="986.852"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood
              floodOpacity="0"
              result="BackgroundImageFix"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="144.46"
              result="effect1_foregroundBlur_5866_2591"
            />
          </filter>
        </defs>
      </svg>
      <svg
        width="636"
        height="1101"
        viewBox="0 0 636 1101"
        fill="none"
        className="absolute right-0 bottom-0 hidden lg:block"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          style={{ mixBlendMode: 'hard-light' }}
          opacity="0.3"
          filter="url(#filter0_f_7006_1349)"
        >
          <ellipse
            cx="599.815"
            cy="550.289"
            rx="373.466"
            ry="158.163"
            transform="rotate(-142.079 599.815 550.289)"
            fill="#2E42FF"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_7006_1349"
            x="0.57666"
            y="0.0779419"
            width="1198.48"
            height="1100.42"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood
              floodOpacity="0"
              result="BackgroundImageFix"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="144.46"
              result="effect1_foregroundBlur_7006_1349"
            />
          </filter>
        </defs>
      </svg>
    </>
  );
};
