import clsx from 'clsx';
import { ITabOption } from '@/interfaces/layout';
import { ReactNode } from 'react';

interface IProps {
  tabs: ITabOption[];
  handleTabChange: (tab: ITabOption) => void;
  activeTab: string;
  wrapperClass?: string;
  tabId: string;
  lastActiveTab?: ReactNode | null;
  tabClassName?: string;
  activeTabClassName?: string;
  tabsListClassName?: string;
}

export const OutlineTabs = ({
  tabs,
  handleTabChange,
  activeTab,
  wrapperClass,
  tabId,
  lastActiveTab,
  tabClassName,
  activeTabClassName,
  tabsListClassName,
}: IProps) => {
  return (
    <div
      id={tabId}
      className={clsx('w-full flex my-0 mobile:my-[40px]', {
        [`${wrapperClass}`]: wrapperClass,
      })}
    >
      <ul
        className={clsx(
          'w-full flex gap-base flex-wrap text-center text-base leading-[25.6px] justify-between mobile:justify-start',
          {
            [`${tabsListClassName}`]: tabsListClassName,
          },
        )}
      >
        {tabs.map((tab, index) => (
          <li
            key={index}
            onClick={() => {
              handleTabChange(tab);
            }}
            className={clsx(
              `
                px-[12px] py-[9px] cursor-pointer
                transition duration-300 rounded-[6px]
                border-[1px] w-[88px] select-none
                hover:border-purple-2
              `,
              {
                ['font-semibold text-purple-9 bg-purple-2']: activeTab === tab.value,
                ['border-grey-1 text-black-10 hover:scale-[1.05] hover:text-purple-9']: activeTab !== tab.value,
                [`${activeTabClassName}`]: activeTabClassName && activeTab === tab.value,
                [`${tabClassName}`]: tabClassName,
              },
            )}
          >
            <div>{tab.value}</div>
          </li>
        ))}
        {lastActiveTab}
      </ul>
    </div>
  );
};
