'use client';

import { LoopingVideo } from '@/components/LoopingVideo';
import './index.scss';
import Link from 'next/link';
import { routeNames } from '@/constants/routes';
import { useRef, useState } from 'react';
import { AnimationBlock } from '@/components/AnimationBlock';
import { Arrow } from '@/images/svg/Arrow';

interface IProps {
  title: string;
  tags: {
    title: string;
  }[];
  description: string;
  video: string;
  href: string;
  animated?: boolean;
}

export const CaseCard = ({ description, title, video, tags, href, animated = false }: IProps) => {
  const blockRef = useRef(null);
  const [isVideoPlaying, setVideoPlay] = useState(false);

  const content = (
    <div
      className="
        flex flex-col max-w-[320px] h-[583px] w-full
        relative bg-purple-14 rounded-[10px] [&_video]:rounded-t-[10px]
        mobile:max-w-[443px] group z-[2]
      "
      onMouseOver={() => setVideoPlay(true)}
      onMouseLeave={() => setVideoPlay(false)}
    >
      <div className="relative ">
        <LoopingVideo
          src={video}
          className="!z-[1] !m-0 !w-full max-w-[320px] mobile:max-w-[443px] max-h-[289px] z-[1]"
          isPlaying={isVideoPlaying}
        />
        <svg
          width="140"
          height="87"
          viewBox="0 0 140 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute bottom-0 right-0 z-[2]"
        >
          <path
            d="M140 0V87H0L140 0Z"
            fill="#3A275A"
          />
        </svg>
      </div>

      <div className="z-[3] p-[30px] h-full flex flex-col">
        <h3 className="text-purple-7 uppercase font-medium mb-[5px]">{title}</h3>
        {description && (
          <Link href={`${href}#nav-header`}>
            <p
              className="
                text-grey-1 text-4xl leading-[43.2px]
                transition-colors duration-300 ease-out group-hover:text-purple-7
              "
            >
              {description}
            </p>
          </Link>
        )}

        <div className="flex justify-between items-center mt-[auto]">
          {tags && (
            <div className="flex gap-[8px] flex-wrap">
              {tags.map((tag) => {
                return (
                  <Link
                    key={tag.title}
                    href={{
                      pathname: routeNames.ourWork,
                      query: { category: tag.title, page: 1 },
                    }}
                    className="hover:scale-[1.05] duration-300"
                  >
                    <span
                      className="
                        transition-colors duration-300 ease-out
                        flex items-center justify-center bg-transparent
                        rounded-full border-[1px] px-[10px] py-[5px]
                        font-medium text-center
                        text-white border-white
                      "
                    >
                      {tag.title}
                    </span>
                  </Link>
                );
              })}
            </div>
          )}
          <div className="flex items-center">
            <Link
              href={`${href}#nav-header`}
              className="hover:opacity-80 text-green-7 transition duration-300 ease-out hover:scale-[1.05]"
            >
              Expand
            </Link>
            <Arrow variant="home-green" />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className="case-card-link"
      ref={blockRef}
    >
      {animated ? <AnimationBlock blockRef={blockRef}>{content}</AnimationBlock> : content}
    </div>
  );
};
