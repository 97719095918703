'use client';

import { ArrowButtonLink } from '@/components/Links/ArrowButtonLink';
import { useRef } from 'react';
import { AnimationBlock } from '@/components/AnimationBlock';
import { ListMarker } from '@/images/svg/ListMarker';

interface IProps {
  content: { text: string }[];
  link: {
    to: string;
    title: string;
  };
  animated?: boolean;
}

export const Book = ({ content, link, animated = false }: IProps) => {
  const headingRef = useRef(null);
  const contentRef = useRef(null);

  const heading = (
    <h3
      ref={headingRef}
      className="
          mobile:text-[48px] xl-plus:text-[54px] mobile:leading-[67.2px] text-purple-1
          [&>span]:font-monoItalic [&>span]:text-purple-8
          tracking-[-0.96px] text-3xl leading-[42.2px]
        "
    >
      Does this sound <span>like you?</span>
    </h3>
  );

  const contentElement = (
    <div
      className="w-full flex flex-col items-start gap-[23px] mobile:gap-[40px] max-w-[1106px] xl-plus:max-w-[1376px]"
      ref={contentRef}
    >
      {content.map(({ text }, index) => (
        <div
          key={index}
          className="flex mobile:gap-[24px] gap-xs"
        >
          <div className="lg:pt-[11px] lg:scale-[1.2]">
            <ListMarker />
          </div>
          <p
            className="
              text-purple-7 lg:text-[28px] [&>span]:font-monoItalic [&>span]:font-light
              [&>span]:tracking-[-0.4px] text-left [&>span]:text-purple-1
            "
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </div>
      ))}
    </div>
  );

  return (
    <section
      className="bg-black-11 flex flex-col justify-center relative overflow-x-clip gap-[40px]
      items-center pt-[40px] pb-[40px] mobile:pb-[85px] px-[16px]"
    >
      {animated ? <AnimationBlock blockRef={headingRef}>{heading}</AnimationBlock> : heading}

      {animated ? <AnimationBlock blockRef={contentRef}>{contentElement}</AnimationBlock> : contentElement}

      <ArrowButtonLink
        arrowVariant="secondary"
        gradientPosition="!h-[1500%]"
        gradient="bg-conic-gradient-green"
        buttonTextClassname="!font-normal"
        buttonClassname="
          text-white flex lg:mt-[20px] whitespace-nowrap
          h-[53.02px] text-base leading-base mobile:w-[320px] mobile:h-[48px] border-white50
        "
        to={link.to}
      >
        {link.title}
      </ArrowButtonLink>
    </section>
  );
};
