'use client';

import { CaseCard } from '@/components/Cards/CaseCard';
import { Lens } from './Lens';
import { ArrowButtonLink } from '@/components/Links/ArrowButtonLink';

interface IProps {
  data: {
    video: string;
    title: string;
    description: string;
    href: string;
    tags: {
      title: string;
    }[];
  }[];
  link: {
    to: string;
    title: string;
  };
}

export const Cases = ({ data, link }: IProps) => (
  <div
    className="bg-black-11 flex flex-col justify-center relative overflow-x-clip gap-[40px]
        mobile:gap-[55px] items-center pt-[40px] pb-[40px] mobile:pb-[80px] px-[16px]"
  >
    <div
      className="relative flex gap-[40px] justify-center mobile:justify-evenly
      flex-wrap [@media(min-width:1440px)]:gap-[20px]
      [@media(min-width:1440px)]:flex-nowrap [@media(min-width:1440px)]justify-center"
    >
      {data.map(({ ...rest }, index) => (
        <CaseCard
          animated
          {...rest}
          key={index}
        />
      ))}
    </div>
    <Lens />
    <ArrowButtonLink
      arrowVariant="secondary"
      gradientPosition="!h-[1000%]"
      gradient="bg-conic-gradient-green"
      buttonTextClassname="!font-normal"
      buttonClassname="text-white flex mt-[32px] lg:mt-0 whitespace-nowrap
        w-[133.77px] h-[53.02px] text-[17.67px] leading-[17.67px]
        mobile:w-[418px] mobile:h-[48px] mobile:text-[16px] mobile:leading-[22px] border-white50"
      to={link.to}
    >
      {link.title}
    </ArrowButtonLink>
  </div>
);
